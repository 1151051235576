import React, { useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import styles from './styles/Auth.module.css'
import logo from './assets/taleton-logo.png'
// import logo from './assets/Taleton.svg'

import {
	Text,
	useToasts,
	Spacer,
	Loading,
	Image,
	Button,
	Input,
	Grid,
	Card } from '@geist-ui/react'

// import {
// 	Loading,
// 	ArrowRight } from '@geist-ui/react-icons'

export default props => {

	const [loading, setLoading]   		  = useState(false)
	const [email, setEmail]   			  = useState('')
	
	const [, setToast] = useToasts()

	const history = useHistory()
	
	const toastMessage = (type, msg) => setToast({
		text: msg,
		type,
	})

	const signup = () => {

	  	if (email == '') {
	  		// setEmptyCredentials(true)
	  		return false
	  	}

	  	// setEmptyCredentials(false)
	  	setLoading(true)

	  	let signupUrl =  process.env.API_URL + '/signup/email'

	  	try {
			fetch(signupUrl, {
				method: 'POST',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify(
					{
						"email": email
					})
			})
			.then(response => {
				console.log(response)
				return response.json()
			})
			.then(data => {
				console.log(data)
				if (data.status == 200) {
					let nextPage = '/registration/confirm-email'
					history.push(nextPage)
					// router.push(nextPage)
				} else  {
					toastMessage('warning', data.message)
					setLoading(false)
				}
			})
		} catch (error) {
	  		console.error('Connection failed.');
	  		console.error('Error:', error);
	  		setLoading(false)
		}
	}

	// useEffect(() => {
	//
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>
			<Grid xs={24}>
				<Card style={{height: "600px"}} style={{border: 'none', textAlign: 'center'}}>
					<Card.Content>
						<div className={styles.checkout_logo}>
							<Image
								src={logo}
								alt="Taleton"
								width="160px"
							/>
							<Spacer />
						</div>

						<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>Create your account now.</Text>
						
						<Spacer y={2}/>

						<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Input size="large" width="300px" placeholder="Email address" onChange={e => setEmail(e.target.value)} />
						</div>

						<Spacer y={2} />

						
							<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
								<Button loading={loading}  size="large" style={{width: '300px'}} type="secondary-light" onClick={() => signup()}>
									Create an account
								</Button>		
							</div>
						

						<Spacer y={2}/>
						
						<Text span type="secondary" size="1em" justify="center">By clicking signup, you agree to our <br/> <Link to="/static/terms-of-service">Terms of Service</Link> and <Link to="/static/privacy-policy">Privacy Policy</Link>.</Text>
						<Spacer />
						
						{/*<Text span type="secondary" size="1em" justify="center">Have a complex company use case? <br/><Link to="/static/enterprise">Get enterprise-grade assistance</Link>.</Text>
						<Spacer />*/}
						<Spacer />
						<Text span type="secondary" size="1em" justify="center">Already have an account? <Link to="/login">Login</Link></Text>
					</Card.Content>
					<Card.Footer style={{border: 'none'}}>
					</Card.Footer>
				</Card>
			</Grid>
		</div>
	)
}

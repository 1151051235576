import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import logo from './assets/taleton-logo.png'
// import logo from './assets/Taleton.svg'

import {
	Text,
	Spacer,
	Loading,
	Image,
	Button,
	Input,
	Card } from '@geist-ui/react'

export default props => {

	// const [loading, setLoading]   = useState(true)

	// useEffect(() => {
	//
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>
		
			<Card width="600px" style={{height: "600px"}} style={{border: 'none', textAlign: 'center'}}>
				<Card.Content>
					<div>
						<Image
							src={logo}
							alt="Taleton"
							width="160px"
						/>
						<Spacer />
					</div>

					<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>Reset password</Text>
					
					<Spacer y={2}/>

					<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
						<Input size="large" width="300px" placeholder="Email address" />
					</div>
					
					<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
						<Button size="large" style={{width: '300px'}} type="secondary-light">Reset password</Button>	
					</div>	
					
					<Spacer y={2}/>
					
					<Text span type="secondary" size="1em" justify="center">Have an account? <Link to="/login">Login</Link></Text>
					<Spacer />
					
					<Spacer />
				</Card.Content>
				<Card.Footer style={{border: 'none'}}>
				</Card.Footer>
			</Card>
		
		</div>
	)
}

import React from 'react'
import { Link } from 'react-router-dom'

import {
	Spacer,
	Text,
	Grid,
	Button,
	Input,
	Code,
	Tooltip,
	Card } from '@geist-ui/react'

import {
	Check,
	ArrowRight,
	DollarSign as Bank } from '@geist-ui/react-icons'

export default props => {

	return (
		<div style={{marginLeft: '285px', maxWidth: '1200px'}}>
			
			<Spacer y={2} />

			<h1>Notifications</h1>

			<Card style={{border: 'none'}}>
				
				<Spacer y={1} />
				
				<Text>
					None
				</Text>

				<Spacer y={1} />
			</Card>	
		</div>
	)
}

import React from 'react'
import logo from '../assets/taleton-logo.png'

// import styles from '../styles/Auth.module.css'
// import logo from '../assets/Taleton.svg'

import {
	Text,
	Spacer,
	Loading,
	Select,
	Image,
	Button,
	Input,
	Tooltip,
	Col,
	Grid,
	Card } from '@geist-ui/react'

import {
	Check,
	Globe,
	Mail,
	Info,
	Smile,
	ArrowRight } from '@geist-ui/react-icons'

export default props => {
	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>
			<Grid xs={24}>
				<Card style={{height: '600px', border: 'none', textAlign: 'center'}}>
					<Card.Content>
						<div className="checkout_logo">
							<Image
								src={logo}
								alt="Taleton"
								width="160px"
							/>
							<Spacer />
						</div>

						<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>Confirm your email address</Text>
						
						<div justify="center" style={{ marginTop: '20px' }}>	
							<Mail size="32" />
						</div>
						
						<Text style={{fontSize: '16px'}}>
							Please click on the link we sent to your email address to create your account. <br/>
							If you're unable to find the email, please check your spam folder.
						</Text>


					</Card.Content>
					<Card.Footer style={{border: 'none'}}></Card.Footer>
				</Card>
			</Grid>	
		</div>
	)
}

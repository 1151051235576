import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// import logo from '../assets/Taleton.svg'
import logo from '../assets/taleton-logo.png'
import Question from '../components/question'
// import '../styles/Auth.module.css'

import {
	Spacer,
	Loading,
	Select,
	Image,
	Button,
	Card } from '@geist-ui/react'

import { Check, Circle } from '@geist-ui/react-icons'

export default props => {

	var questions = [
		{id: 'kyc1', description: 'What is your current employment status?', answers: ['Employed', 'Self-employed (or business owner)', 'Retired', 'Student', 'Not employed']},
		{id: 'kyc2', description: 'Are you using your account for personal or corporate purposes?', answers: ['Personal account', 'Corporate company']},
		{id: 'kyc3', description: 'What is your main activity?', answers: ['Trader', 'E-commerce', 'Services', 'Consulting', 'Real estate', 'Other']},
		{id: 'kyc4', description: 'Are you or any relatives of you politically exposed persons? (PEPs)', answers: ['Yes', 'No']},
		{id: 'kyc5', description: 'What is your yearly net income in euro?', answers: ['Up to €10.000', '€10.001 - €20.000', '€20.001 - €50.000', '€50.001 - €100.000', 'More than €100.000']},
	]

	const [loading, setLoading]       = useState(false)
	const [index, setIndex] 	  	  = useState(1)
	const [question, setQuestion] 	  = useState(questions[0])
	const [tempAnswer, setTempAnswer] = useState()
	const [answers, setAnswers]   	  = useState([])

	const history = useHistory()

	const nextQuestion = () => {
		if (!tempAnswer) {
		  return // Wait for a choice
		}

		let a = answers
		a.push(tempAnswer)
		setAnswers(a)

		console.log(answers)

		if (index >= questions.length) {

		  saveKYC()		
		  return // MOVE NEXT PAGE	
		}
		
		setIndex(index + 1)
		console.log(index)
		setQuestion(questions[index])
	}

	const logQ = (q) => {
		setTempAnswer(q)
		console.log(q)
	}

	const saveKYC = () => {
		let kycUrl = process.env.API_URL + '/user/kyc' 
		let token  = window.location.pathname.split('/')[3]

		setLoading(true)

	  	try {
			fetch(kycUrl, {
				method: 'POST',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify(
					{
						"kyc": answers,
						"token": token
					})
			})
			.then(response => {
				console.log(response)
				return response.json()
			})
			.then(data => {
				console.log(data)
				if (data.status == 200) {
					localStorage.setItem("taleton_kyc", true)
					let nextPage = '/registration/identity-verification/' + token
					history.push(nextPage)
				}
				// setLoading(false)
			})
		} catch (error) {
	  		console.error('Error:', error);
		}
	}



	// useEffect(() => {
	// 	nextQuestion()
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>			
			<Card style={{height: '600px', border: 'none', textAlign: 'center'}}>
				<Card.Content>
					<div>
						<Image
							src={logo}
							alt="Taleton"
							width="160px"
						/>
						<Spacer />
					</div>

					{/*<Text className={styles.main_title}>Welcome to Payland! <br />Just two more steps to start.</Text>*/}
					
					<Spacer y={2}/>
					

					<div style={{display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>	
										
							{ index } / { questions.length } questions
					
					</div>

					<Question q={question} choice={logQ} />

					<Spacer y={2} />

					
					<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
						<Button loading={loading} size="large" style={{width: '300px'}} type="secondary-light" onClick={() => nextQuestion()}>
							Continue
						</Button>		
					</div>
					
					
				</Card.Content>
				<Card.Footer style={{border: 'none'}}></Card.Footer>
			</Card>
		</div>
	)
}

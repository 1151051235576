import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom"
import PasswordStrengthBar from 'react-password-strength-bar'
import logo from '../assets/taleton-logo.png'
// import logo from '../assets/Taleton.svg'
// import '../styles/Auth.module.css'

// import Link from '../../../components/link'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/high-res.css'


import {
	Text,
	Spacer,
	Loading,
	Select,
	Image,
	Button,
	Input,
	Card } from '@geist-ui/react'

// import {
// 	Loading } from '@geist-ui/react-icons'

export default props => {

	const [emptyCredentials, setEmptyCredentials] = useState(false)
	const [loading, setLoading]         		  = useState(false)
	const [firstName, setFirstName] 			  = useState('')
	const [lastName, setLastName] 				  = useState('')
	const [password, setPassword] 				  = useState('')
	const [country, setCountry] 				  = useState('')
	const [city, setCity] 						  = useState('')
	const [address, setAddress] 				  = useState('')
	const [zipcode, setZipcode] 				  = useState('')
	const [errorMessage, setErrorMessage]	  	  = useState('')
	// const [phoneNumber, setPhoneNumber] 		  = useState('')

	const history = useHistory()

	const formattedPhoneNumber = (number) => {
		console.log(number)		
	}

	const selectedCountry = (country) => {
		setCountry(country)
	}

	const paste = () => {
		let token = window.location.pathname.split('/')[3]
		console.log(token)
		console.log(firstName)
		console.log(lastName)
		console.log(country)
	}

	const saveJWT = (jwt, encoded_token) => {
		localStorage.setItem("taleton_jwt", jwt)
		localStorage.setItem("taleton_encoded_jwt", encoded_token)
	}

	const signup = () => {

		let token = window.location.pathname.split('/')[3]

		if (token.length < 20) {
			return false
		}

	  	if (firstName == '' || lastName == '' || password == '' || country == '') {
	  		setEmptyCredentials(true)
	  		return false
	  	}

	  	setEmptyCredentials(false)
	  	setLoading(true)

	  	let signupUrl =  process.env.API_URL + '/signup' //process.env.API_URL + 'signup'

	  	try {
			fetch(signupUrl, {
				method: 'POST',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify(
					{
						"first_name": firstName,
						"last_name": lastName,
						"password": password,
						"country_of_residence": country,
						"city": city,
						"address": address,
						"zip_code": zipcode,
						"token": token,
					})
			})
			.then(response => {
				console.log(response)
				return response.json()
			})
			.then(data => {
				console.log(data)
				if (data.status == 200) {
					console.log(data)
					saveJWT(data.jwt, data.token)

					let nextPage = '/registration/welcome-intro/' + data.token
					history.push(nextPage)
				}
				if (data.status == 403) {
					setErrorMessage(data.message)
				}
				setLoading(false)
			})
		} catch (error) {
	  		console.error('Connection failed.');
	  		console.error('Error:', error);
	  		setLoading(false)
		}
	}


	// useEffect(() => {
	//
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>
			<Card style={{height: '600px', border: 'none', textAlign: 'center'}}>
				<Card.Content>
					<div>
						<Image
							src={logo}
							alt="Taleton"
							width="160px"
						/>
						<Spacer />
					</div>

					<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>Almost there, <br/> create your account.</Text>
					
					<Spacer y={2}/>

					{ errorMessage ? (
						<Text style={{color: 'red'}}>{errorMessage}</Text>
					):(
						null
					)}

					<form autoComplete="off">
					
						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>						
							<Input size="large" width="300px" placeholder="First name" onChange={e => setFirstName(e.target.value)} />
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Input size="large" width="300px" placeholder="Last name" onChange={e => setLastName(e.target.value)} defaultValue={lastName} />
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
							<Input.Password size="large" width="300px" placeholder="Password" autoComplete="new-password" onChange={e => setPassword(e.target.value)} />
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<PasswordStrengthBar password={password} style={{width: '300px'}} />
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>							
							<Select placeholder="Country of residence" size="medium" style={{height: "40px"}} width="300px" onChange={e => selectedCountry(e)}>
								<Select.Option style={{fontSize: '1em'}} value="Austria">Austria</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Belgium">Belgium</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Bulgaria">Bulgaria</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Croatia">Croatia</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Cyprus">Cyprus</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Czechia">Czechia</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Denmark">Denmark</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Estonia">Estonia</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Finland">Finland</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="France">France</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Germany">Germany</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Greece">Greece</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Hungary">Hungary</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Iceland">Iceland</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Ireland">Ireland</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Italy">Italy</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Latvia">Latvia</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Liechtenstein">Liechtenstein</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Lithuania">Lithuania</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Luxembourg">Luxembourg</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Malta">Malta</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Netherlands">Netherlands</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Norway">Norway</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Poland">Poland</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Portugal">Portugal</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Romania">Romania</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Slovakia">Slovakia</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Slovenia">Slovenia</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Spain">Spain</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Sweden">Sweden</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="Switzerland">Switzerland</Select.Option>
								<Select.Option style={{fontSize: '1em'}} value="United Kingdom">United Kingdom</Select.Option>
							</Select>
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Input size="large" width="300px" placeholder="City" onChange={e => setCity(e.target.value)} />
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Input size="large" width="300px" placeholder="Address" onChange={e => setAddress(e.target.value)} />
						</div>

						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Input size="large" width="300px" placeholder="Zip code" onChange={e => setZipcode(e.target.value)} />
						</div>

						{/*
						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<div style={{width: '300px'}}>
								<PhoneInput
								  country={'gr'}
								  placeholder="+30 69x xxx xxxx"
								  value={phoneNumber}
								  autoFormat={false}
								  countryCodeEditable={false}
								  enableSearch={true}
								  disableSearchIcon={true}
								  searchPlaceholder="Search country"
								  onChange={phone => formattedPhoneNumber(phone)}
								/>
							</div>	
						</div>
						
						<Grid xs={24} md={12}>
							<Input.Password size="large" width="100%" type="password" placeholder="Repeat password" />
						</Grid>

						<Grid xs={24} md={12}>
							<Button size="large" type="">Back</Button>	
						</Grid>
						*/}	    
						
						<Spacer y={2} />

						
						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Button loading={loading} size="large" style={{width: '300px'}} type="secondary-light" onClick={() => signup()}>
								Create an account
							</Button>		
						</div>

					</form>	

					<Spacer />

				</Card.Content>
				<Card.Footer style={{border: 'none'}}></Card.Footer>
			</Card>
		</div>
	)
}

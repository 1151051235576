import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import btc_logo from '../assets/coins/btc.svg'
import eth_logo from '../assets/coins/eth.svg'
import usdt_logo from '../assets/coins/usdt.svg'
import usdc_logo from '../assets/coins/usdc.svg'
import euro_logo from '../assets/coins/eur.svg'
import Metamask_logo from '../assets/metamask-logo.svg'

import {
	Spacer,
	Text,
	Grid,
	useToasts,
	useClipboard,
	Loading,
	Tooltip,
	Card } from '@geist-ui/react'

import {
	Info,
	Copy,
	CreditCard } from '@geist-ui/react-icons'




export default props => {

	const [balance, setBalance] = useState('Loading...')

	const assets = [
		{name: 'ETH', address: props.address, logo: eth_logo, color: '#7928ca' },
		{name: 'USDC', address: props.address, logo: usdc_logo, color: '#50e3c2' },
		{name: 'USDT', address: props.address, logo: usdt_logo, color: '#469f46' }
	]

	const asset = assets.filter(e => e.name == props.name)[0]

	const connectedChain = (chainId) => {
		let chains  = [
			{id: '0x1', name: 'Ethereum mainnet)'}, 
			{id: '0x5', name: 'Goerli testnet'}
		]

		let AllowedChain = chains.filter(e => e.id == chainId)[0]
		console.log(AllowedChain === undefined ? 'Unkown/unsupported network' : AllowedChain.name)
	}

	const depositAsset = (asset) => {
		
		console.log(asset)

		if (asset.name == 'ETH') {

			var transactionParam = {
				to: asset.address,
				from: '0x49a22755f885b4ff430c4EF3734A66473f0f39C0',
				value: '0x38D7EA4C68000'
			}
		}

		if (asset.name == 'USDC') {

			var transactionParam = {
				to: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F', // USDC contract address
				from: '0x49a22755f885b4ff430c4EF3734A66473f0f39C0',
				data: '0xa9059cbb000000000000000000000000' + '03c1126b3aebd8ed5505be0d6846398534e41b19' + '0000000000000000000000000000000000000000000000000000000000b71b00' // 12 USDC
			}   // 0x03c1126b3aebd8ed5505be0d6846398534e41b19
		}


		ethereum.request({
		    	method: 'eth_sendTransaction',
		    	params: [transactionParam],
		    })
		    .then((txHash) => {
		    	console.log(txHash)
			})
		    .catch((error) => console.error)
	}

	const trimAddress = (a) => {
		return a.slice(0,12) + '...' + a.slice(36, 42)
	} 

	const [, setToast] = useToasts()
	const { copy } = useClipboard()

	const copyHandler = () => {
	copy(props.address)
		setToast({ text: 'Address copied' })
	}


	const updateBalance = () => {
		

		var url = 'http://localhost:3000/api/balance?address=' + props.address + '&token=' + props.name


		console.log(url)

		try {
			fetch(url, {method: 'GET'})
			.then(response => {
				return response.json()
			})
			.then(data => {
				// Work with JSON data here
				console.log(data)
				setBalance(data.balance)
				props.balance(props.name, data.balance)
			})
		} catch (error) {
			console.error('Error:', error)
		}
	}


	useEffect(() => {
		// console.log(ethereum)
		console.log(props)
		if (props.address)
			updateBalance()
	}, [props])

	return (
		<Card style={{backgroundColor: asset.color, color: 'white'}} width="100%" height="220px">

			<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '25px'}}>
				<img 
					src={asset.logo}
					alt={asset.name}
					style={{margin: 0, height: '36px', width: '36px'}}
				/>
				<Text h4 my={0}>Ethereum Mainnet</Text>
			</div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Text b>{ balance === 'Loading...' ? balance : (asset.name === 'ETH' ? balance.toFixed(4) : balance) + ' ' + asset.name}</Text>
				<span>
					<Text b style={{float: 'left'}}>
						
		 				{trimAddress(asset.address)}
						
					</Text>
					<span style={{display: 'inline-block', cursor: 'pointer', marginLeft: '10px'}}>
						<Copy onClick={() => copyHandler()} />
					</span>
				</span>	
			</div>

			<div style={{marginTop: '46px'}}>
				<span
					style={{
						padding: '6px 10px 10px',
						backgroundColor: 'white',
						color: 'black',
						borderRadius: '30px',
						display: 'inline-block',
						fontWeight: 700,
						cursor: 'pointer'
					}}
				>
					<span onClick={() => depositAsset(asset)}>Deposit with Metamask</span>
				</span>	
			</div>	

		</Card>
	)
}
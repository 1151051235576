import React from 'react'
import { BrowserRouter as Router, Link, Route, Redirect, Switch } from 'react-router-dom'

// Pages
// import Landing from './pages/landing' 
import Login from './login' 
import Signup from './signup' 
import Assets from './assets' 
import Notifications from './notifications' 
import Transactions from './transactions' 
import Help from './help' 
import Settings from './settings/settings'  
import Cards from './cards' 
import Rewards from './rewards' 
import LeftNav from './components/nav' 

import WelcomeIntro from './registration/welcomeIntro' 
import ConfirmedEmail from './registration/confirmedEmail' 
import ConfirmEmail from './registration/confirmEmail' 
import AmlQuestions from './registration/AmlQuestions' 
import IdentityVerification from './registration/identityVerification' 
import NewPassword from './newPassword' 
import ResetPassword from './resetPassword' 

function App ({match}, props) {

	const Index = () => {
		return (
		 <div style={{textAlign: 'center', margin: '50px auto'}}>
			 <h2 >Welcome</h2>
			 <Link to="/login">login</Link>
		 </div>
		)    
	}

	const loggedIn = localStorage.getItem("taleton_jwt") !== null ? true : false
	// const kyc 	   = localStorage.getItem("taleton_kyc") !== null ? true : false // loggedIn && kyc
	console.log(loggedIn)
	
	return (
		<div className="container">
				<Router>
					{ (loggedIn) ? (
						<div className="dashboard_viewport">
							<LeftNav />
							<Switch>
								{/* <Route path="/integrations" component={Integrations} /> */}
								<Route path="/settings" component={Settings} />
								<Route path="/notifications" component={Notifications} />
								<Route path="/help" component={Help} />
								<Route path="/transactions" component={Transactions} />
								<Route path="/invite" component={Rewards} />
								<Route path="/cards" component={Cards} />
								<Route path="/assets" component={Assets} />
								<Route path="/" component={Assets} />
							</Switch>
						</div>
					):(
						<>
							<Switch>
								<Redirect exact from="/" to="/login" />
								<Route path="/login" component={Login} />
								<Route path="/signup" component={Signup} />
								<Route path="/reset-password" component={ResetPassword} />
								<Route path="/new-password" component={NewPassword} />
								<Route path="/registration/welcome-intro" component={WelcomeIntro} />
								<Route path="/registration/confirmed-email" component={ConfirmedEmail} />
								<Route path="/registration/confirm-email" component={ConfirmEmail} />
								<Route path="/registration/aml-questions" component={AmlQuestions} />
								<Route path="/registration/identity-verification" component={IdentityVerification} />

							</Switch>
						</>
					)}
				</Router>
		</div>    
	)
} 

export default App

import React, {useState, useEffect} from 'react'

import {
	Check,
	CheckInCircle,
	Circle } from '@geist-ui/react-icons'

export default props => {

	const [click, setClick] = useState([false, false, false, false, false])

	const choose = (id, a, k) => {
		console.log(a)
		let checkStatus = [false, false, false, false, false] 
		checkStatus[k] = true
		setClick(checkStatus)
		let selection = {id: id, answer: a}
		props.choice(selection)
	}

	useEffect(() => {
		setClick([false, false, false, false, false] )
		console.log(click)
	}, [props.q]);

	return (
		<>
			<div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
				<div style={{fontSize: '22px', marginBottom: '20px'}}>					
					{ props.q.description}
				</div>
			</div>

			<div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>	
				<div style={{display: 'block'}}>
					{ props.q && props.q.answers.map((a, k) => {
						return (
							<span key={k} style={{display: 'block', overflow: 'hidden', width: '300px', fontSize: '16px', margin: '10px 0'}}>
								<span style={{float: 'left'}}>{a}</span>
								<span style={{float: 'right'}}>
									{ click[k] ? (
										<Check style={{color: '#95b595'}} />
									):(
										<Circle onClick={() => choose(props.q.id, a, k)} />
									)}
								</span>
							</span>
						)
					})}
				</div>	
			</div>		
		</>
	)
}

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Dashboard.module.css'
// import logo from '../assets/Taleton.svg'
import logo from '../assets/taleton-logo.png'

import {
	Image,
	Text,
	Spacer,
	Button,
	Select,
	Avatar } from '@geist-ui/react'

import {
	Sun,
	Moon,
	Bell,
	Emoji,
	ShoppingBag,
	Globe,
	Plus,
	CreditCard,
	Activity,
	Settings,
	Zap,
	Briefcase,
	HelpCircle as Help,
	Box,
	Key,
	Lock,
	Repeat,
	LifeBuoy,
	DollarSign as Bank,
	Search,
	Gift,
	Package } from '@geist-ui/react-icons'

export default props => {

	const [user, setUser] = useState()
	const [loading, setLoading] = useState(true)

	const getUser = () => {
		var url = process.env.API_URL + '/user'

		var requestOptions = {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem("taleton_jwt")
			}
		}

		try {
			fetch(url, requestOptions)
			.then(response => {
				return response.json()
			})
			.then(data => {
				// Work with JSON data here
				console.log(data)
				if (data.user) {				
					setUser(data.user.first_name + ' ' + data.user.last_name)
					// setLoading(false)
				}
			})
		} catch (error) {
			console.error('Error:', error)
		}
	}

	useEffect(() => {
		getUser()	
	}, [])


	return (
		<div className="leftNav">
			<div className="logo">
				<Image
					src={logo}
					alt="Taleton"
					style={{height: '36px', width: '122px'}}
				/>
			</div>
			<div className="verticalMenu">
			  
			  	    <Spacer y={2}/>
			  	    

					<Spacer y={1}/>

					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <LifeBuoy />
					  	</span>
						<Link to="/assets">Assets</Link>
					</div>
					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <CreditCard />
					  	</span>
				  	    <Link to="/cards">Cards</Link>
					</div>
					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <ShoppingBag />
					  	</span>
				  	    <Link to="/transactions">Transactions</Link>
					</div>					
					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <Bell />
					  	</span>
				  	    <Link to="/notifications">Notifications <span style={{backgroundColor: '#bdb6b6', color: 'white', padding: '1px 6px', marginLeft: '4px', borderRadius: '3px' }}>0</span> </Link>
					</div>
					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <Gift />
					  	</span>
				  	    <Link to="/invite">Invite & Earn</Link>
					</div>
					{/*
					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <Settings />
					  	</span>
				  	    <Link to="/settings">Settings</Link>
					</div>
					*/}
					<div className="menuItem">
					  	<span className="menuIcon">
					  	  <Help />
					  	</span>
				  	    <Link to="/help">Help</Link>
					</div>
					  
					<div className="menuItem">
					  	{/*<Avatar
							src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmtRbf-Sw_4TRHSPre5JO-Kt4eipSTitNidQ&usqp=CAU'}
							size="small"
							className="avatar"
						/>*/}
						<span className="menuIcon">
					  	  <Emoji />
					  	</span>
						<a href="#">{user}</a>
					</div>

				  <Spacer y={2}/>



				  {/*
				  <div className="menuItem">
						<div className="chooseAccount">
							<Select placeholder="Personal" initialValue="1" style={{height: '65px', width: '200px'}}>
								<Select.Option value="1" style={{padding: '0.5em 1em'}}>
									<Avatar
										src={'https://vercel.com/api/www/avatar/40bd1a926592f8f5d503f08c69d9dc66c79bf85c?s=160'}
										size="small"
										className="avatar"
									/>
									<Text span size="medium" style={{marginLeft: '0.5em', fontSize: '14px'}}>Kostas</Text>
								</Select.Option>
								<Select.Option value="2" style={{padding: '0.5em 1em'}}>
									<Avatar
										src={'https://user-images.githubusercontent.com/2018617/29999916-41242160-9027-11e7-91c3-2610f49bf3cc.png'}
										size="small"
										className="avatar"
									/>
									<Text span size="medium" style={{marginLeft: '0.5em'}}>Exotic Ltd.</Text>
								</Select.Option>
								<Select.Option value="3" disabled style={{padding: '0.5em 1em'}}>
									<Button className="newBusiness" icon={<Plus />} type="abort" style={{width: '120px'}}>Create new business</Button>
								</Select.Option>
							</Select>
						</div>
					</div>
					*/}

				  <div className="navCredit">
			  	    <Text small style={{ color: '#aaa' }}>&copy; { new Date().getFullYear() }, Taleton Labs AG</Text>
			  	    {/* <Text small style={{ color: '#aaa' }}></Text> */}    
				  </div>
			</div>
		</div>
	)
}

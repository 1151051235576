import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/Dashboard.module.css'
import Password from './password'
import TwoFactor from './twoFactor'
import Activity from './activity'
import Privacy from './privacy'

// import {useState, useEffect} from 'react'

import {
	Text,
	Spacer,
	Divider,
	Loading,
	Image,
	Button,
	Input,
	Select,
	Code,
	Tabs,
	Row,
	Col,
	Grid,
	Card } from '@geist-ui/react'

import {
	Face,
	ArrowRight,
	Lock } from '@geist-ui/react-icons'

export default props => {

	//	const [loading, setLoading]   = useState(true)

	return (
		<div style={{marginLeft: '285px'}}>
			<Spacer y={2} />

			<Text h1>Settings</Text>

			<Spacer y={1} />
			<Password />

			<Spacer y={1} />
			<Divider y={0} />

			<Spacer y={1} />
			<TwoFactor />

			<Spacer y={1} />
			<Divider y={0} />

			<Spacer y={1} />
			<Activity />

			<Spacer y={1} />
			<Divider y={0} />

			<Spacer y={1} />
			<Privacy />

		</div>
	)
}

import React, { useState } from 'react'
import logo from '../assets/taleton-logo.png'

// import logo from '../assets/Taleton.svg'
// import styles from '../styles/Auth.module.css'

import {
	Grid,
	Image,
	Spacer,
	Button,
	Card,
	Text } from '@geist-ui/react'

import {
	Check,
	Globe,
	Info,
	Smile,
	Circle,
	ArrowRight } from '@geist-ui/react-icons'

export default props => {

	localStorage.setItem("taleton_kyc", true)

	const kyc = () => {
		let token = window.location.pathname.split('/')[3]
		let url   = process.env.KYC_URL + '/?session=' + token

		window.open(url)
	}


	// const persistSession = () => {
	// 	let token = window.location.pathname.split('/')[3]
	// 	let jwt   = decodeURIComponent(escape(window.atob(token)));
	// 	localStorage.setItem("payland_session", jwt)
	// }

	// useEffect(() => {
	// 	persistSession()
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>			
			<Card style={{height: '600px', border: 'none', textAlign: 'center'}}>
				<Card.Content>
					<div className={styles.checkout_logo}>
						<Image
							src={logo}
							alt="Taleton"
							width="160px"
						/>
						<Spacer />
					</div>

					{/*<Text className={styles.main_title}>Welcome to Payland! <br />Just two more steps to start.</Text>*/}
					
					<Spacer y={2}/>
					<form>

						<div justify="center" style={{ marginBottom: '5px' }}>	
											
								<Text>Have your identity document ready</Text>
						
						</div>

						<Spacer y={2} />

						<div justify="center" style={{ marginBottom: '10px' }}>
							<Button size="large" style={{width: '300px'}} type="secondary-light" onClick={() => kyc()}>
								Start
							</Button>		
						</div>

						<div justify="center" style={{ marginBottom: '10px' }}>
							
							<Text><a href="/assets">I 'll do it later</a></Text>
							
						</div>

					</form>		
					
				</Card.Content>
				<Card.Footer style={{border: 'none'}}></Card.Footer>
			</Card>
		</div>
	)
}

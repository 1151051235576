import React 	from 'react'
import ReactDOM from 'react-dom'
import { GeistProvider, CssBaseline } from '@geist-ui/react'
import './styles/global.css'

import App  from './App'
// import Drift from 'react-driftjs'

const Payland = ({ Component, pageProps }) => {
  return (

    <GeistProvider>
      <CssBaseline />
  	  <App />

  	  {/*<Drift appId="2b7bb9z7c55r" />*/}
    </GeistProvider>

  )
}

const app = document.getElementById("app")
ReactDOM.render(<Payland />, app)

import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import logo from '../assets/taleton-logo.png'

// import logo from '../assets/Taleton.svg'
// import '../styles/Auth.module.css'
// import Link from 'next/link'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/high-res.css'


import {
	Text,
	Spacer,
	Loading,
	Select,
	Image,
	Button,
	Tooltip,
	Card } from '@geist-ui/react'

import {
	Check,
	Globe,
	Info,
	Smile,
	ArrowRight } from '@geist-ui/react-icons'

export default props => {

	const [emptyCredentials, setEmptyCredentials] = useState(false)
	const [loading, setLoading]         		  = useState(false)
	const [firstName, setFirstName] 			  = useState('')
	const [lastName, setLastName] 				  = useState('')
	const [country, setCountry] 				  = useState('')
	const [password, setPassword] 				  = useState('')

	const history = useHistory()

	const formattedPhoneNumber = (number) => {
		console.log(number)		
	}

	const selectedCountry = (country) => {
		setCountry(country)
	}

	const paste = () => {
		let token = window.location.pathname.split('/')[2]
		console.log(token)
		console.log(firstName)
		console.log(lastName)
		console.log(country)
	}

	const KYCQuestions = () => {
		let nextPage = '/registration/aml-questions/' + window.location.pathname.split('/')[3]
		history.push(nextPage)
	}

	// useEffect(() => {
	//
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>
			<Card style={{height: '600px', border: 'none', textAlign: 'center'}}>
				<Card.Content>
					<div>
						<Image
							src={logo}
							alt="Taleton"
							width="160px"
						/>
						<Spacer />
					</div>

					<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>Welcome to Taleton! <br />Just two more steps to start.</Text>
					
					<Spacer y={2}/>
			
					<div style={{display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>	
						<div style={{borderRadius: '50%', backgroundColor: '#95b595', color: 'white', width: '40px', height: '40px' }}>					
							<div style={{display: 'inline-block', marginTop: '5px'}}>
								<Check size="32" />
							</div>
						</div>
						<span style={{marginLeft: '10px', marginTop: '7px'}}>
							Create your account
						</span>	
					</div>

					<div style={{display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>	
						<div style={{borderRadius: '50%', backgroundColor: 'rgb(197 185 123)', color: 'white', width: '40px', height: '40px' }}>					
							<div style={{display: 'inline-block', marginTop: '5px'}}>
								<Globe size="30" />
							</div>
						</div>
						<span style={{marginLeft: '10px', marginTop: '-5px'}}>
							<span>
								Answer 6 questions required by EU <br /> anti-money laundering regulations
							</span>	
						</span>	
						<span style={{marginLeft: '15px', marginTop: '8px'}}>
							<Tooltip text={'We are compliant with the EU anti-money laundering regulation (AML5), we need to ask you some questions. Please note that all personal information will always be strictly treated as confidential and stored securely.'} type="dark">
								<Info size="22" />	
							</Tooltip>	
						</span>
					</div>

					<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>	
						<div style={{borderRadius: '50%', backgroundColor: 'rgb(197 185 123)', color: 'white', width: '40px', height: '40px' }}>					
							<div style={{display: 'inline-block', marginTop: '5px'}}>
								<Smile size="30" />
							</div>
						</div>
						<span style={{marginLeft: '10px', marginTop: '7px'}}>
							Verify your identity (KYC)
						</span>	
						<span style={{marginLeft: '15px', marginTop: '8px'}}>
							<Tooltip text={'A very common KYC procedure. We need a passport or a driving license and a photo of you. Usally takes less than 5 minutes.'} type="dark">
								<Info size="22" />	
							</Tooltip>	
						</span>
					</div>

					<Spacer y={2} />

					{ loading ? (
						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Loading>Saving</Loading>
						</div>
					):(
						<div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
							<Button size="large" style={{width: '300px'}} type="secondary-light" onClick={() => KYCQuestions()}>
								Continue
							</Button>		
						</div>
					)}
					
				</Card.Content>
				<Card.Footer style={{border: 'none'}}></Card.Footer>
			</Card>
		</div>
	)
}

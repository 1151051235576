import React from 'react'
import { Link } from 'react-router-dom'

import {
	Text,
	Spacer,
	Divider,
	Button,
	Card } from '@geist-ui/react'

import {
	Check,
	Activity,
	ArrowRight} from '@geist-ui/react-icons'


export default props => {

	return (
		<>
			<Card style={{border: 'none'}}>
				<Card.Content>
					<span style={{float: 'left'}}>
						<Activity /> &nbsp;
					</span>
					<Text b>Account Activity</Text>
				</Card.Content>
				
				<Card.Content>
					<Text>Recent activity on your account.</Text>

					<Spacer y={2} />

					<Button auto type="success-light" icon={<ArrowRight />} iconRight>
						Inspect
					</Button>
				</Card.Content>
			</Card>
		</>
	)
}

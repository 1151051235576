import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Bitcoin_logo from './assets/coins/btc.svg'
import Ethereum_logo from './assets/coins/eth.svg'
import Usdt_logo from './assets/coins/usdt.svg'
import Usdc_logo from './assets/coins/usdc.svg'
import Euro_logo from './assets/coins/eur.svg'
import Metamask_logo from './assets/metamask-logo.svg'
import AssetCard from './components/assetCard'

import {
	Spacer,
	Text,
	Grid,
	Tag,
	Tabs,
	Button,
	Modal,
	Divider,
	Description,
	Input,
	Snippet,
	Image,
	Code,
	Tooltip,
	Card } from '@geist-ui/react'

import {
	Check,
	ArrowRight,	
	Info,
	Copy,
	CreditCard,
	ArrowDownLeft,
	ArrowUpRight,
	MoreVertical,
	Database,
	DollarSign as Bank } from '@geist-ui/react-icons'

export default props => {

	const [open, setOpen]	    = useState(false)
	const [loading, setLoading] = useState(true)
	const [address, setAddress]	= useState('')
	const [eth, setEth]   		= useState(0)
	const [usdc, setUsdc] 		= useState(0)
	const [usdt, setUsdt] 		= useState(0)
	
	const [prices, setPrices]   = useState({"ETH": {"USD": 0, "GBP": 0, "EUR": 0 }, "USDC": {"USD": 0, "GBP": 0, "EUR": 0 }, "USDT": {"USD": 0, "GBP": 0, "EUR": 0 } })

	const [currency, setCurrency] = useState('USD')
	const [totalBalance, setTotalBalance] = useState(0)

	// const address = '0x738BF51D21604Ae7E5d232B8e06FE7c923714a28'

	const openModal = () => {
		setOpen(true)
	}

	const closeModal = (event) => {
	  setOpen(false)
	}

	const runProvider = () => {
		// Connected Network
		ethereum.on('connect', (chain) => {
			// Time to reload your interface with accounts[0]!
			connectedChain(chain.chainId)
		})

		ethereum.on('chainChanged', (chainId) => {
			// Time to reload your interface with accounts[0]!
			connectedChain(chainId)
		})
	}

	const getWallet = () => {
		var url = process.env.API_URL + '/user/wallet'

		var requestOptions = {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem("taleton_jwt")
			}
		}

		try {
			fetch(url, requestOptions)
			.then(response => {
				return response.json()
			})
			.then(data => {
				// Work with JSON data here
				console.log(data)
				setAddress(data.wallet)
			})
		} catch (error) {
			console.error('Error:', error)
		}
	}

	const assetBalance = (name, amount) => {
		console.log(name)
		console.log(amount)
		if (name == 'ETH')
			setEth(amount)
		if (name == 'USDC')
			setUsdc(amount)
		if (name == 'USDT')
			setUsdt(amount)
	}

	const updateTotalBalance = (prices) => {
		let balance = (eth * prices.ETH.USD + usdc * prices.USDC.USD + usdt * prices.USDT.USD)
		let roundedBalance = Math.round((balance + Number.EPSILON) * 100) / 100
		




		console.log('++++++++++++++++++++++++++++++++++')
		console.log(eth)
		console.log(usdc)
		console.log(usdt)
		console.log(balance)
		console.log('++++++++++++++++++++++++++++++++++')

		setTotalBalance(roundedBalance)
	}

	const getPrices = () => {
		let apiKey = 'e1cf61e7fc9712bf0baefbb8e75f8dbf12e6856e384a850b7669534ebe258ac5'
		var url    = 'https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,USDC,USDT&tsyms=USD,GBP,EUR&api_key=' + apiKey

		try {
			fetch(url, {method: 'GET'})
			.then(response => {
				return response.json()
			})
			.then(data => {
				// Work with JSON data here
				console.log(data)
				setPrices(data)
				//updateTotalBalance(data)
			})
		} catch (error) {
			console.error('Error:', error)
		}
	}

	useEffect(() => {
		getWallet()	
		getPrices()		
		// const interval = setInterval(() => getPrices(), 20000)
		// return () => {
		// 	clearInterval(interval);
		// }

		// console.log(ethereum)
	}, [])
	
	return (
		<div style={{marginLeft: '285px', maxWidth: '1200px'}}>
			
			<Spacer y={2} />

			<Text h1>Assets</Text>

			<Card style={{border: 'none'}}>
				

				<Spacer y={1} />
				
				<Text b style={{color: 'red'}}>
					THIS IS TESTNET !
				</Text>

				<Spacer y={1} />

				<Text>
					Load your assets, get interest up to 6% APY. <br /> Spend easily up to 50% LTV anytime with a prepaid card.
				</Text>

				<Spacer y={1} />

				<Text h3>
					Total balance: { Math.round(((eth * prices.ETH.USD + usdc * prices.USDC.USD + usdt * prices.USDT.USD) + Number.EPSILON) * 100) / 100 } USD
				</Text>

				<Spacer y={1} />

				<Grid.Container gap={1.5}>
			        <Grid xs={9}>
						<AssetCard name='ETH' address={address} balance={assetBalance} />
			        </Grid>
			        <Grid xs={9}>
			        	<AssetCard name='USDC' address={address} balance={assetBalance} />
			        </Grid>
			        <Grid xs={9}>
			        	<AssetCard name='USDT' address={address} balance={assetBalance} />
			        </Grid>
			        {/*
			        <Grid xs={9}>
			        	<div className="skeleton" style={{width: '100%', height: '220px'}}></div>
			        </Grid>
			    	*/}
			    </Grid.Container>

				<Spacer y={5} />
			</Card>	
		</div>
	)
}

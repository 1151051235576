import React, {useState, useEffect} from 'react'
import { Link, useHistory } from "react-router-dom"
import logo from './assets/taleton-logo.png'
// import logo from './assets/Taleton.svg'

import {
	Text,
	useToasts,
	Spacer,
	Loading,
	Image,
	Button,
	Input,
	Grid,
	Card } from '@geist-ui/react'

// import {
// 	Loading,
// 	ArrowRight } from '@geist-ui/react-icons'

export default props => {

	const [loading, setLoading]   		 		  = useState(false)
	const [emptyCredentials, setEmptyCredentials] = useState(false)
	const [email, setEmail]		   		 		  = useState('')
	const [password, setPassword]  		 		  = useState('')
	const [code, setCode]  				 		  = useState('')
	const [errorMessage, setErrorMessage]		  = useState('')
	const [TwoFactor, setTwoFactor]				  = useState(false)


	const [, setToast] = useToasts()

	const history = useHistory()

	const toastMessage = (type, msg) => setToast({
		text: msg,
		type,
	})

	const saveJWT = (jwt, encoded_token) => {
		localStorage.setItem("taleton_jwt", jwt)
		localStorage.setItem("taleton_encoded_jwt", encoded_token)
	}

	const login = () => {

	  	if (email == '' || password == '') {
	  		setEmptyCredentials(true)
	  		return false
	  	}

	  	setEmptyCredentials(false)
	  	setLoading(true)

	  	let loginUrl =  process.env.API_URL + '/login'

	  	try {
			fetch(loginUrl, {
				method: 'POST',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify(
					{
						"email": email,
						"password": password
					})
			})
			.then(response => {
				console.log(response)
				return response.json()
			})
			.then(data => {
				console.log(data)
				if (data.status == 200) {
					// toastMessage('default', "Logging in ...")
						
					saveJWT(data.jwt, data.token)
					console.log(data)		
					let nextPage = '/'
					window.location.href = nextPage

					// setTwoFactor(true)
					// setLoading(false)
				} else  {
					toastMessage('warning', "Sorry, that password isn't right.")
					setLoading(false)
				}
			})
		} catch (error) {
	  		console.error('Connection failed.');
	  		console.error('Error:', error);
	  		setLoading(false)
		}
	}

	const login2fa = () => {

	  	if (email == '' || password == '' || code == '') {
	  		setEmptyCredentials(true)
	  		return false
	  	}

	  	setEmptyCredentials(false)
	  	setLoading(true)

	  	let login2faUrl =  process.env.API_URL + '/login/2fa'

	  	try {
			fetch(login2faUrl, {
				method: 'POST',
				headers: {'Content-Type':'application/json'},
				body: JSON.stringify(
					{
						"email": email,
						"password": password,
						"code": code
					})
			})
			.then(response => {
				console.log(response)
				return response.json()
			})
			.then(data => {
				console.log(data)
				if (data.status == 200) {
					// SAVE JWT
					saveJWT(data.jwt, data.token)
					console.log(data)		
					let nextPage = '/'
					window.location.href = nextPage
				} else  {
					toastMessage('warning', "Sorry, that code isn't right.")
					setLoading(false)
				}
			})
		} catch (error) {
	  		console.error('Connection failed.');
	  		console.error('Error:', error);
	  		setLoading(false)
		}
	}

	const obscureEmail = (email) => {
		let [alias, domain] = email.split('@');
		let [name, tld] = domain.split('.');
		return `${alias[0]}${new Array(alias.length).join('*')}@${name[0]}${new Array(name.length).join('*')}.${tld}`;
	}

	// useEffect(() => {
	//
	// }, []);

	return (
		<div style={{"minHeight":"100vh","display":"flex","flexDirection":"column","justifyContent":"center","alignItems":"center"}}>
			<Grid xs={24}>


					<Card style={{height: "600px"}} style={{border: 'none', textAlign: 'center'}}>
						<Card.Content>
							<div>
								<Image
									src="https://taleton.xyz/taleton_newlogo.png"
									alt="Taleton"
									width="160px"
								/>
								<Spacer />
							</div>


							{ TwoFactor ? (
								<>
									<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>
										Verify it's you.										
									</Text>
									
									<Text style={{fontSize: '22px'}}>For your security, we want to make sure it's really you.</Text>
									<Text style={{fontSize: '16px'}}>
										We have sent a <b>security code</b> in your email: ({obscureEmail(email)}) <br />
										Please find the code and paste into the Verification Code field above.
									</Text>



									<Spacer y={2}/>

									<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
										<Input size="large" width="300px" placeholder="Verification Code" onChange={e => setCode(e.target.value)} />
									</div>

									<Spacer y={2} />

									<div justify="center">
										<Button loading={loading} size="large" style={{width: '300px'}} type="secondary-light" onClick={() => login2fa()}>Verify & Login</Button>	
									</div>

									<Spacer y={2}/>
							
									<Spacer />
									<Text span type="secondary" size="1em" justify="center">
										Make sure to check also your junk folder. <br />
										Can't find the code? <Link to="/2fa/email-resend">Resend</Link>, <br />
										try a different <Link to="#">Verification method</Link> or <Link to="/2fa/email-resend">Contact us</Link>.
									</Text>

								</>
							):(
								<>

									<Text style={{"position":"relative","zIndex":"1","lineHeight":"1.15em","fontSize":"36px","fontWeight":"500"}}>Log-in to <br /> your account.</Text>
									
									<Spacer y={2}/>

									<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
										<Input size="large" width="300px" placeholder="Email address" onChange={e => setEmail(e.target.value)} />
									</div>
									
									<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
										<Input.Password autoComplete="new-password" size="large" width="300px" placeholder="Password" onChange={e => setPassword(e.target.value)} />
									</div>

									<Spacer y={2} />

									<div justify="center">
										<Button loading={loading} size="large" style={{width: '300px'}} type="secondary-light" onClick={() => login()}>Login</Button>	
									</div>	

									<Spacer y={2}/>
							
									<Text span type="secondary" size="1em" justify="center">Forgot password? <Link to="/reset-password">Reset</Link></Text>
									<br />
									{/*<Text span type="secondary" size="1em" justify="center">Don't have an account? <Link to="/signup">Sign Up</Link></Text>*/}

								</>	
							)}

							
							
						</Card.Content>
						<Card.Footer style={{border: 'none'}}>
						</Card.Footer>
					</Card>

			</Grid>
		</div>
	)
}

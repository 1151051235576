import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import {
	Text,
	Spacer,
	Divider,
	Button,
	Checkbox,
	Modal,
	Grid,
	Card } from '@geist-ui/react'

import {Shield,	ArrowRight} from '@geist-ui/react-icons'


export default props => {

	const [state, setState] 			= useState(false)
	const [action, setAction] 			= useState()
	const [description, setDescription] = useState()

	const handlerData = () => {
		setState(true)
		setAction('Data')
		setDescription('a copy')
	}

	const handlerDeletion = () => {
		setState(true)
		setAction('Deletion')
		setDescription('deletion of some or full')
	}

	const handlerCorrection = () => {
		setState(true)
		setAction('Correction')
		setDescription('correction')
	}

	const closeHandler = (event) => {
	  setState(false)
	  console.log('closed')
	}

	return (
		<Card style={{border: 'none'}}>
			  <Card.Content>
				<span style={{float: 'left'}}>
					<Shield /> &nbsp;
				</span>
				<Text b>Privacy Options</Text>
			  </Card.Content>
			  
			  <Card.Content>
				<Text>You have a number of rights over your personal data. <br />For more detailed information on these choices, please read our full Privacy Policy and our Cookie Policy.</Text>

				<Spacer y={1} />
				<Text p b>Request Data</Text>
				<Text>Request a copy of my personal data held by Taleton.</Text>

				<Button onClick={handlerData} auto type="success-light" icon={<ArrowRight />} iconRight>
					Request
				</Button>

				<Modal width="38rem" open={state} onClose={closeHandler}>
					<Modal.Title style={{justifyContent: 'left'}}>Request {action}</Modal.Title>
					<Modal.Content>
					  <Divider y={0} />
					  <p>Request {description} of your personal data held by Taleton. Use the form below to send your request to Taleton. Requested information will be provided in accordance with applicable law.</p>
					  <Divider y={0} />
					  <Text><Checkbox checked={false} size="medium">Contact information</Checkbox></Text>
					  <Text><Checkbox checked={true} size="medium">Transaction history</Checkbox></Text>
					  <Text><Checkbox checked={true} size="medium">Activity log</Checkbox></Text>
					  <Text><Checkbox checked={true} size="medium">Financial information</Checkbox></Text>

					  <Spacer y={2} />

								<Grid.Container gap={2}>
								<Grid>
									<Button auto passive onClick={() => setState(false)}>Cancel</Button>
								</Grid>
								<Grid>
							  <Button auto type="success-light" icon={<ArrowRight />} iconRight>
											Send Request
										</Button>
								</Grid>
							</Grid.Container>
					</Modal.Content>
				</Modal>

				<Spacer />
				<Text p b>Request Deletion</Text>
				<Text>Request deletion of some or all of my data.</Text>

				<Button onClick={handlerDeletion} auto type="success-light" icon={<ArrowRight />} iconRight>
					Request
				</Button>

				<Spacer />
				<Text p b>Request Correction</Text>
				<Text>Request correction of inaccurate personal data.</Text>

				<Button onClick={handlerCorrection} auto type="success-light" icon={<ArrowRight />} iconRight>
					Request
				</Button>


			  </Card.Content>
		</Card>

	)
}

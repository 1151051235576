import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
	Spacer,
	Text,
	Grid,
	Button,
	Input,
	Code,
	Tooltip,
	Card } from '@geist-ui/react'

import {
	Check,
	Copy,
	ArrowRight,
	DollarSign as Bank } from '@geist-ui/react-icons'

export default props => {
	const [show, setShow] = useState(false)
	const [number, setNumber] = useState('')
	const [cvv, setCvv] = useState('')
	const [exp, setExp] = useState('')

	const showDetails = () => {
		setNumber('5354 5634 0001 8009 4545')
		setCvv('028')
		setExp('11 / 27')

		setShow(show => !show)
	}

	return (
		<div style={{marginLeft: '285px', maxWidth: '1200px'}}>
			
			<Spacer y={2} />

			<h1>Cards</h1>

			<Card style={{border: 'none'}}>
				
				<Spacer y={1} />
				
				<Text b style={{color: 'red'}}>
					THIS IS A DEMO CARD !
				</Text>

				<Spacer y={1} />

				<Text>
					Spend money easily with virtual or physical cards at any store or online. <br />
					Manage, block cards, set limits and configure other options you need to make your everyday payments run more efficiently.
				</Text>

				<Spacer y={1} />

				<Text h3>
					Available balance: 0.00 USD
				</Text>

				<Spacer y={1} />

				<Grid xs={9}>
					<Card type='purple' width="100%" height="220px">
						<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '25px'}}>
							
							<Text h4 my={0}>Virtual card 1</Text>
						</div>
						
						<div style={{display: 'block', height: '30px'}}>
							<Text b> 
								{ show ? (
									<span>
							            <Text b style={{float: 'left'}}>{ number }</Text>
										<span style={{display: 'inline-block', cursor: 'pointer', marginLeft: '10px'}}>
											<Copy />
										</span>
									</span>	
								):(
									<span>*** 4545</span>
								)}
							</Text>
						</div>

						<div style={{display: 'flex'}}>
							<Text b style={{marginRight: '20px'}}> Expiry date &nbsp; { show ? exp : '** / **' }</Text>
							<Text b> CVV &nbsp; { show ? cvv : '***' }</Text>
						</div>

						<div style={{display: 'flex', marginTop: '40px', justifyContent: 'space-between'}}>
							<span
								style={{
									padding: '6px 10px 10px',
									backgroundColor: 'white',
									color: 'black',
									borderRadius: '30px',
									display: 'inline-block',
									fontWeight: 700,
									cursor: 'pointer'
								}}
							>
								<span onClick={() => showDetails()}>{ show ? 'Hide details' : 'Show details' }</span>
							</span>	
					
							<img style={{width: '76px'}} src="https://walletto.eu/wp-content/uploads/2023/02/front-page-mastercard.svg" />
							{ /* <img src="https://walletto.eu/wp-content/uploads/2023/02/front-page-visa.svg" /> */}
						</div>
					</Card>
				</Grid>      


			</Card>	
		</div>
	)
}

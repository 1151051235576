import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import logo from './assets/Taleton.svg'
import './styles/Auth.module.css'

import {
	Text,
	Spacer,
	Loading,
	Image,
	Button,
	Input,
	Card } from '@geist-ui/react'

export default props => {

	// const [loading, setLoading]   = useState(true)

	// useEffect(() => {
	//
	// }, []);

	return (
		<div className="auth">
			<Card width="600px" style={{height: "600px"}} style={{border: 'none', textAlign: 'center'}}>
				<Card.Content>
					<div>
						<Image
							src={logo}
							alt="Payland"
							width="160px"
						/>
						<Spacer />
					</div>

					<Text className="main_title">Set your password</Text>
					
					<Spacer y={2}/>

					<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
						<Input.Password size="large" width="300px" placeholder="Password" />
					</div>
					
					<div justify="center" style={{display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
						<Input.Password size="large" width="300px" placeholder="Repeat password" />
					</div>

					<div justify="center">
						<Button size="large" style={{width: '300px'}} type="secondary-light">Save</Button>	
					</div>	
					
					<Spacer y={2}/>
					
					<Spacer />
					{/* <Text span type="secondary" size="1em" justify="center">Don't have an account? Sign Up</Text> */}
				</Card.Content>
				<Card.Footer style={{border: 'none'}}>
				</Card.Footer>
			</Card>
		</div>
	)
}

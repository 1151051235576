import React, {useState} from 'react'
import { Link } from 'react-router-dom'

import {
	Text,
	Spacer,
	Divider,
	Modal,
	Code,
	Image,
	Loading,
	Button,
	Input,
	Card } from '@geist-ui/react'

import {
	ArrowRight,
	Check,
	Lock } from '@geist-ui/react-icons'



export default props => {

	const [open, setOpen]	    = useState(false)
	const [loading, setLoading] = useState(false)

	const openModal = () => {
		setOpen(true)
	}

	const closeModal = (event) => {
	  setOpen(false)
	}

	return (
		<>
			<Card style={{border: 'none'}}>
				<Card.Content>
					<span style={{float: 'left'}}>
						<Text b>****</Text> &nbsp;
					</span>
					<Text b>Password</Text>
				</Card.Content>
				
				<Card.Content>
					<Text>Always keep your password safe. <br />Change your password with a new one. You will need your current password.</Text>

					<Spacer y={2} />

					<Button onClick={openModal} auto type="success-light" icon={<ArrowRight />} iconRight>
						Change Password
					</Button>
				</Card.Content>
			</Card>

			<Modal width="22rem" open={open} onClose={closeModal}>
				<Modal.Title style={{justifyContent: 'left'}}>Change password</Modal.Title>
				<Modal.Content>
					<Divider y={0} />
					<Spacer y={2}/>
					<div style={{marginBottom: '5px'}}>Current password</div>
					<div justify="center" style={{display: 'flex', marginBottom: '10px' }}>
						<Input.Password size="large" width="100%" placeholder="Password" />
					</div>

					{/*
					<div style={{marginBottom: '5px'}}>2FA Code</div>
					<div justify="center" style={{display: 'flex', marginBottom: '10px' }}>
						<Input size="large" width="100%" placeholder="One time code" />
					</div>
					*/}

					<Spacer y={2}/>

					<div style={{marginBottom: '5px'}}>New password</div>
					<div justify="center" style={{display: 'flex', marginBottom: '10px' }}>
						<Input.Password size="large" width="100%" placeholder="New password" />
					</div>
					
					<div justify="center" style={{display: 'flex', marginBottom: '10px' }}>
						<Input.Password size="large" width="100%" placeholder="Repeat new password" />
					</div>

				  	<Spacer y={2} />
				

				  	<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<Button auto passive onClick={() => setOpen(false)} style={{float: 'left'}}>Cancel</Button>
						
						<Button auto type="success-light" icon={<ArrowRight />} iconRight style={{flost: 'right'}}>
							Save
						</Button>
					</div>	
				</Modal.Content>
			</Modal>

		</>
	)
}


import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import {
	Text,
	Spacer,
	Divider,
	Modal,
	Code,
	Image,
	Loading,
	Button,
	Input,
	Card } from '@geist-ui/react'

import {
	ArrowRight,
	Check,
	Lock } from '@geist-ui/react-icons'



export default props => {

	const [open, setOpen]	    = useState(false)
	const [loading, setLoading] = useState(false)
	const [QRImage, setQRImage] = useState('')

	const openModal = () => {
		setOpen(true)
	}

	const closeModal = (event) => {
	  setOpen(false)
	}


	useEffect(() => {
		// getAvailableDeps(props.output._id)
		fetch("https://www.google.com/chart?chs=200x200&chld=M|0&cht=qr&chl=otpauth://totp/Example%3Aalice%40google.com%3Fsecret%3DJBSWY3DPEHPK3PXP%26issuer%3DTaleton")
		.then(response => response.blob())
		.then(imageBlob => {
			// Then create a local URL for that image and print it 
			const imageObjectURL = URL.createObjectURL(imageBlob)
			console.log(imageObjectURL)
			setQRImage(imageObjectURL)
		});
	}, [])

	return (
		<>
			<Card style={{border: 'none'}}>
				<Card.Content>
					<span style={{float: 'left'}}>
						<Lock /> &nbsp;
					</span>
					<Text b>Two-Factor Authentication (2FA)</Text>
				</Card.Content>
				
				<Card.Content>
					<Text>Add one layer of security to your account using two-factor authentication (2FA). <br />Two-factor authentication improves security by requiring you to enter a one-time 6-digits code generated by an app each time you sign-in.</Text>

					<Spacer y={2} />

					<span style={{color: 'green', marginRight: '25px', display: 'inline-block'}}>
						<span style={{display: 'inline-block', float: 'left', marginTop: '3px'}}>
							<Check size={32} />
						</span>
						<span style={{display: 'inline-block', marginTop: '7px'}}>Enabled</span>
					</span>
					<Button onClick={openModal} auto type="success-light" icon={<ArrowRight />} iconRight>
						Setup
					</Button>
				</Card.Content>
			</Card>

			<Modal width="52rem" open={open} onClose={closeModal}>
				<Modal.Title style={{justifyContent: 'left'}}>Two-factor authentication</Modal.Title>
				<Modal.Content>
					<Divider y={0} />
					<p>Two-factor authentication improves security by requiring you to enter a one-time 6-digits code generated by an app (like <Code>Google Authenticator</Code>, <Code>Twilio Authy</Code>, <Code>TOTP Authenticator</Code>) each time you sign-in.</p>
					<Divider y={1} />

					{loading ? (
						<div style={{padding: '200px 0'}}>
							<Loading>Loading</Loading>
						</div>	
					):(
						<>
							<Text>First, scan the QR code in your preferred two-factor authentication app. </Text>

							<Image src="https://image-charts.com/chart?chs=150x150&cht=qr&chl=terra1h3ey3e2nk69qskg9fq2srp4nr05ank35ylxk8g&choe=UTF-8" style={{width: '300px', display: 'block'}} />

						  	<Spacer y={1} />

						  	<Text>Then, enter the 6-digit authentication code provided by your app. </Text>

						  	<Input size="large" placeholder="Authentication code. Eg 819612" width="300px" />

						  	<Spacer y={2} />
						</> 	
					)}

				  	<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<Button auto passive onClick={() => setOpen(false)} style={{float: 'left'}}>Cancel</Button>
						
						<Button auto type="success-light" icon={<ArrowRight />} iconRight style={{flost: 'right'}}>
							Enable
						</Button>
					</div>	
				</Modal.Content>
			</Modal>

		</>
	)
}

import React from 'react'
import { Link } from 'react-router-dom'

import {
	Spacer,
	Text,
	Code,
	Tooltip,
	Card } from '@geist-ui/react'

import {
	Copy,
	Send,
	Gift } from '@geist-ui/react-icons'

export default props => {

	return (
		<div style={{marginLeft: '285px', maxWidth: '1200px'}}>
			
			<Spacer y={2} />
			<Text h1>Invite Friends & Earn Crypto Together</Text>

			<Card style={{border: 'none'}}>
				

				<Spacer y={2} />

				<Gift size={52} />

				<Spacer y={1} />

				<h5>For every friend who joins and tops up 100€ or more in assets, we’ll reward both of you with 20€ in ETH.</h5>

				<Spacer y={1} />

				<h5>How it works</h5>	
				
				<Text>Just copy your unique link, have your friends open a Taleton account using your link. <br />Once a friend tops up 100€, you’ll both get 20€ worth of ETH within 30 days.</Text>

				<Spacer y={1} />
				<pre style={{float: 'left'}}>https://app.taleton.xyz/join/ref/9bNHj3oWrt1G</pre>
				<span style={{display: 'inline-block', cursor: 'pointer', marginLeft: '20px', marginTop: '35px'}}>
					<Tooltip style={{marginLeft: '20px'}} text={'Copy to clipboard'} type="dark">
						<Copy />	
					</Tooltip>	
					<Tooltip style={{marginLeft: '20px'}} text={'Send via email'} type="dark">
						<Send />	
					</Tooltip>	
				</span>

				<Spacer y={5} />
			</Card>	
		</div>
	)
}
